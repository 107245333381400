import React from 'react'
import InvoiceAccountForm from '../../components/InvoiceComponents/InvoiceAccountingForm'

function InvoiceAccountingPage() {
  return (
    <React.Fragment>
        <InvoiceAccountForm/>
    </React.Fragment>
  )
}
export default InvoiceAccountingPage