import InvoicePaymentForm from "../../components/InvoiceComponents/InvoicePaymentForm";
import React from 'react'

function InvoicePaymentPage() {
  return (
    <React.Fragment>
        <InvoicePaymentForm/>
    </React.Fragment>
  )
}

export default InvoicePaymentPage